import {useState, useEffect} from 'react';
import {Outlet, Link} from "react-router-dom";
import ToggleDropdown from '../reused-components/SidebarTabs';
import AddProducts from '../reused-components/AddProducts';
import Profile from '../reused-components/Profile';
import smlogo from '../../img/sm-logo.svg';
import profileImage from '../../img/user-profile.png';
import customizerLogo from '../../img/customizer-logo.svg';
import EventTypeFilter from "../reused-components/EventTypeFilter";
import ProductEventsLogTable from '../tables/ProductEventsLogTable';
import DarkModeSwitch from "../reused-components/ToggleDarkMode";

export default function EventsLog() {
    document.title = "Product Events Log";

    const [filter, setFilter] = useState('all');

    const handleFilterChange = (selectedFilter) => {
        setFilter(selectedFilter.toLowerCase());
    };

    return (
        <div className="telemetry-page-wrapper">
            <header className="telemetry-header">
                <div className="telemetry-logo-section">
                    <a href="#"><img src={smlogo} alt="Telemetry"/></a>
                </div>
                <div className="telemetry-top">
                    <h1>Product Events Log</h1>
                    <div className="telemetry-top-right">
					<DarkModeSwitch />
                        <AddProducts/>
                        <Profile image={profileImage}/>
                    </div>
                </div>
            </header>
            <main className="telemetry-main">
                <aside className="telemetry-sidebar">
                    <ToggleDropdown tabactive={1} tabactive2={1}/>
                </aside>
                <section className="telemetry-content">
                    <div className="telemetry-filter">
                        <EventTypeFilter onFilterChange={handleFilterChange}/>
                    </div>
                    <div className="telemetry-data-table-wrapper">
                        <div className="telemetry-data-table-container">
                            <ProductEventsLogTable type={filter}/>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}
