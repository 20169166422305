import React, { useState, useEffect } from 'react';

const DarkModeSwitch = () => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const savedMode = localStorage.getItem('darkMode') === 'true';
    setDarkMode(savedMode);
    document.body.classList.toggle('dark-mode', savedMode);
  }, []);

  const toggleDarkMode = () => {
    const newMode = !darkMode;
    setDarkMode(newMode);
    document.body.classList.toggle('dark-mode', newMode);
    localStorage.setItem('darkMode', newMode);
  };

  return (
    <button onClick={toggleDarkMode} className={`dark-mode-switch ${darkMode ? '' : 'day'}`}>
		<div className={`moon ${darkMode ? '' : 'sun'}`}></div>
	</button>
  );
};

export default DarkModeSwitch;