import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ToggleDropdown from '../reused-components/SidebarTabs';
import AddProducts from '../reused-components/AddProducts';
import Profile from '../reused-components/Profile';
import smlogo from '../../img/sm-logo.svg';
import profileImage from '../../img/user-profile.png';
import { ScriptInfo } from '../../services/apiService';
import DarkModeSwitch from "../reused-components/ToggleDarkMode";

export default function SDKIntegration() {
    const { productId } = useParams();
    const [script, setScript] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await ScriptInfo(productId);
                setScript(data);
            } catch (error) {
                console.error('Error fetching Integration Script:', error);
            }
        };
        fetchData();
    }, [productId]);

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = 'https://github.com/WPBrigade/wpb-sdk/archive/refs/heads/main.zip';
        link.setAttribute('download', 'wpb-sdk-main.zip');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleCopyCode = (code) => {
        navigator.clipboard.writeText(code).then(() => {
            alert('Code copied to clipboard!');
        }).catch((err) => console.error('Failed to copy text: ', err));
    };

    document.title = "SDK Integration | Telemetry";
    return (
        <div className="telemetry-page-wrapper">
            <header className="telemetry-header">
                <div className="telemetry-logo-section">
                    <a href="#"><img src={smlogo} alt="Telemetry" /></a>
                </div>
                <div className="telemetry-top">
                    <h1>SDK Integration</h1>
                    <div className="telemetry-top-right">
					<DarkModeSwitch />
                        <AddProducts />
                        <Profile image={profileImage} />
                    </div>
                </div>
            </header>

            <main className="telemetry-main">
                <aside className="telemetry-sidebar">
                    <ToggleDropdown tabactive={1} tabactive2={1} />
                </aside>

                <section className="telemetry-content">
                    <div className="telemetry-setting-tab-content">
                        <div className="telemetry-product">
                            <h2>Integration Guide</h2>
                            <div className="telemetry-add-products">
                                <div ng-transclude="" bis_skin_checked="1">
                                    <p>
                                        The <a target="_blank" href="#">Telemetry WordPress SDK</a> empowers your plugin with features like license activation, automatic updates, usage tracking, and many more capabilities.
                                    </p>
                                    <p>
                                        Additionally, it offers a <a href="#">comprehensive set of methods</a> to protect your premium features from unauthorized use while enabling the addition of contextual upsells and marketing material.
                                    </p>
                                    <p>Let's get you integrated!</p>

                                    {/* Step 1: Download SDK */}
                                    <div className="container">
                                        <div className="step-box"><span className="step-number">1</span></div>
                                        <div className="step-content"><span className="step-text">Download SDK</span></div>
                                    </div>

                                    <div className="telemetry-form-box">
                                        <button type="button" style={{ marginBottom: '20px' }} onClick={handleDownload}>
                                            Download Telemetry WordPress SDK
                                        </button>
                                    </div>

                                    {/* Step 2: Add SDK to Plugin Folder */}
                                    <div className="container">
                                        <div className="step-box"><span className="step-number">2</span></div>
                                        <div className="step-content"><span className="step-text">Add SDK to plugin folder</span></div>
                                    </div>

                                    <p>Copy the unzipped SDK into your plugin's main folder and rename the folder name to wpb-sdk.</p>

                                    {/* Step 3: Init SDK */}
                                    <div className="container">
                                        <div className="step-box"><span className="step-number">3</span></div>
                                        <div className="step-content"><span className="step-text">Init SDK</span></div>
                                    </div>

                                    <form>
                                        <label className='sdk-label'><b>1.</b> Copy the code below AS IS and paste it into the top of your main plugin's PHP file, right after the plugin's header comment:</label>
                                        <div className="code-block-wrapper">
                                            <pre className="code-block"><code>{script}</code></pre>
                                            <div className="sdk-copycode">
                                                <button type="button" className="sdk-copycode-button" onClick={() => handleCopyCode(script)}>Copy Code</button>
                                            </div>
                                        </div>

                                        <label className='sdk-label'><b>2.</b> Add the following three constants to the wp-config.php file of the local WordPress site where you are testing the integration:</label>
                                        <div className="code-block-wrapper">
                                            <pre className="code-block"><code>define('WPB_SDK__DEV_MODE', true);</code></pre>
                                            <div className="sdk-copycode">
                                                <button type="button" className="sdk-copycode-button" onClick={() => handleCopyCode("define('WPB_SDK__DEV_MODE', true);")}>Copy Code</button>
                                            </div>
                                        </div>

                                        <label className='sdk-label'><b>3.</b> You can check out your plugin and see Telemetry is already in action! You may visit the Sites page to see your local WordPress site listed there</label><br/>
                                    </form>

                                    <div className="container">
                                        <div className="step-box"><span className="step-number">4</span></div>
                                        <div className="step-content"><span className="step-text">Testing</span></div>
                                    </div>
                                    <div className="content">
                                        <p>During development and testing, set the <code>WPB_SDK__DEV_MODE</code> constant to <code>true</code> to access <strong>Telemetry Debug</strong> menu item. Add the following to your <code>wp-config.php</code>:</p>
                                        <div className="code-block-wrapper">
                                            <pre className="code-block"><code>define('WPB_SDK__DEV_MODE', true);</code></pre>
                                            <div className="sdk-copycode">
                                                <button type="button" className="sdk-copycode-button" onClick={() => handleCopyCode("define('WPB_SDK__DEV_MODE', true);")}>Copy Code</button>
                                            </div>
                                        </div>

                                        <h3>Testing User Opt-in</h3>
                                        <p>The opt-in screen appears only once. After opting in or skipping, it will not show again. To reset this state, open <strong>Telemetry Debug</strong> in the admin dashboard and delete the accounts. Add the following to your <code>wp-config.php</code>:</p>
                                        <div className="code-block-wrapper">
                                            <pre className="code-block"><code>define('WPB_SDK__DEV_MODE', true);</code></pre>
                                            <div className="sdk-copycode">
                                                <button type="button" className="sdk-copycode-button" onClick={() => handleCopyCode("define('WPB_SDK__DEV_MODE', true);")}>Copy Code</button>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Step 6: Production Debugging */}
                                    <div className="container">
                                        <div className="step-box"><span className="step-number">5</span></div>
                                        <div className="step-content"><span className="step-text">Production Debugging</span></div>
                                    </div>
                                    <p>Access the Telemetry Debug page directly via your-site.com/wp-admin/admin.php?page=Telemetry to get information about the active SDK, Telemetry-powered plugins on the site, users, and more.</p>

                                    {/* Step 7: Misc */}
                                    <div className="container">
                                        <div className="step-box"><span className="step-number">6</span></div>
                                        <div className="step-content"><span className="step-text">Misc</span></div>
                                    </div>
                                    <p>WordPress core only supports a single static handler link to the uninstall hook using register_uninstall_hook() or by adding an uninstall.php file to your plugin's directory. Since Telemetry tracks the uninstall event, using either of these methods will prevent the uninstall data, including user feedback, from being sent.</p>
                                    <p>To resolve this, move the logic from uninstall.php to a function and delete the file. The goal is to have the uninstall logic in a function. Once ready, hook it to the Telemetry uninstall action, which will execute after the uninstall event is reported to the server:</p>
                                    <pre className="code-block"><code>{`<?php
    // Not like register_uninstall_hook(), you do NOT have to use a static function.
    lc_wpb2324234()->add_action('after_uninstall', 'lc_wpb2324234_uninstall_cleanup');
    ?>`}</code></pre>
                                    <div className="sdk-copycode">
                                        <button type="button" className="sdk-copycode-button" onClick={() => handleCopyCode(`<?php
    // Not like register_uninstall_hook(), you do NOT have to use a static function.
    lc_wpb2324234()->add_action('after_uninstall', 'lc_wpb2324234_uninstall_cleanup');
    ?>`)}>Copy Code</button>
                                    </div>
                                </div>
                                <div className="container">
                                        <div className="step-content"><span className="step-text"><strong>Congrats!</strong> <p>The SDK was successfully integrated with your plugin.</p></span></div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}
