import React, {useEffect, useState} from 'react';
import {Outlet, Link, useNavigate} from "react-router-dom";
import ToggleDropdown from '../reused-components/SidebarTabs';
import AddProducts from '../reused-components/AddProducts';
import Profile from '../reused-components/Profile';
import FilterMonth from '../reused-components/FilterMonth';
import ChartDropdown from '../reused-components/ChartDropdown';
import CompareDropdown from "../reused-components/CompareDropdown";
import GmtDropdown from "../reused-components/GmtDropdown";
import UserBlocks from "../reused-components/UserBlocks";
import ReportRange from "../reused-components/RangeDatePicker";
import smlogo from '../../img/sm-logo.svg';
import profileImage from '../../img/user-profile.png';
import userIcon from '../../img/user.svg';

import installIcon from '../../img/installation-icon.svg';
import versionChart from '../../img/version-chart.jpg';
import flag from '../../img/flags/italy-flag.png';
import VersionsStackedAreaChart from "../reused-components/Charts/VersionsStackedAreaChart";
import StackedAreaChart from "../reused-components/Charts/LangLocaleStackedAreaChart";
import TrendlineChart from "../reused-components/Charts/EffectiveGrowthTrendLineChart";
import AudienceActivitiesMixChart from "../reused-components/Charts/AudienceActivitiesMixChart";
import {fetchDashboardStats} from "../../services/apiService";
import moment from 'moment';
import GroupByDropdown from "../reused-components/GroupByDropdown";
import {getDefaultGroupByValue} from "../../utils/dataProcessing";
import {useTelemetryContext} from "../../contexts/TelemetryContext";
import customizerLogo from "../../img/customizer-logo.svg";
import ProductBlock from "../reused-components/ProductBlock"; // Assuming you have moment.js installed

import DarkModeSwitch from "../reused-components/ToggleDarkMode";
export default function StoreDashboard() {
    document.title = "Dashboard | Telemetry";

    const {selectedStore} = useTelemetryContext();
    const navigate = useNavigate();

    const handleProductClick = (product) => () => {
         navigate(`/stores/${product.store_id}/plugins/${product.id}/dashboard`);
    }
    return (
        <div className="telemetry-page-wrapper">
            <header className="telemetry-header">
                <div className="telemetry-logo-section">
                    <a href="#"><img src={smlogo} alt="Telemetry"/></a>
                </div>
                <div className="telemetry-top">
                    <h1>Store Dashboard</h1>
                    <div className="telemetry-top-right">
						<DarkModeSwitch/>
                        <AddProducts/>
                        <Profile image={profileImage}/>
                    </div>
                </div>
            </header>
            <main className="telemetry-main">
                <aside className="telemetry-sidebar">
                    <ToggleDropdown tabactive={0}/>
                </aside>
                <section className="telemetry-content">

                    {selectedStore?.products?.map(product => (
                        <ProductBlock
                            key={product.id}
                            img={product.icon || customizerLogo}
                            inner="telemetry-blue"
                            product={product}
                            number={product.name}
                            userText={product.slug}
                            tooltip={product.slug}
                            handleProductClick={handleProductClick}
                        />
                    ))}

                </section>
            </main>
        </div>
    );
}
