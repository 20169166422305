import React, { useState, useRef } from 'react';
import { Link } from "react-router-dom";
import ToggleDropdown from '../reused-components/SidebarTabs';
import AddProducts from '../reused-components/AddProducts';
import Profile from '../reused-components/Profile';
import smlogo from '../../img/sm-logo.svg';
import profileImage from '../../img/user-profile.png';
import profileicon from '../../img/customer-icon.svg';
import Notification from '../reused-components/Notification';
import { addNewStore } from "../../services/apiService";
import DarkModeSwitch from "../reused-components/ToggleDarkMode";

export default function AddNewStore() {
    // State for form fields
    const [storeTitle, setStoreTitle] = useState('');
    const [storeSlug, setStoreSlug] = useState('');
    const [termsConditions, setTermsConditions] = useState('');
    const [supportForm, setSupportForm] = useState('');
    const [storeIcon, setStoreIcon] = useState(null);
    const [dashboardUrl, setDashboardUrl] = useState('');
    const [dashboardCss, setDashboardCss] = useState('');
    const [dashboardJs, setDashboardJs] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    // File input reference
    const fileInputRef = useRef(null);

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        setErrorMessage('');
        setSuccessMessage('');
    
        const formData = new FormData();
        formData.append('title', storeTitle);
        formData.append('web_url', storeSlug);
        formData.append('terms_and_conditions_url', termsConditions);
        formData.append('support_form_url', supportForm);
        if (storeIcon) formData.append('logo_url', storeIcon); 
        formData.append('user_dashboard_url', dashboardUrl); 
        formData.append('user_dashboard_css', dashboardCss); 
        formData.append('user_dashboard_js', dashboardJs); 
    
        try {
            // Call the API to store the form data
            const response = await addNewStore(formData);
    
            if (response.success) {
                setSuccessMessage('Store created successfully!');
                resetForm();
            } else {
                setErrorMessage('Failed to create store: ' + response.message);
            }
        } catch (error) {
            setErrorMessage('An error occurred: ' + error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    // Reset the form after successful submission
    const resetForm = () => {
        setStoreTitle('');
        setStoreSlug('');
        setTermsConditions('');
        setSupportForm('');
        setStoreIcon(null);
        setDashboardUrl('');
        setDashboardCss('');
        setDashboardJs('');

        // Manually reset file input
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    // Update the document title
    document.title = "Add New Store | Telemetry";

    return (
        <div className="telemetry-page-wrapper">
            <header className="telemetry-header">
                <div className="telemetry-logo-section">
                    <Link to={"/dashboard/"}><img src={smlogo} alt="Telemetry" /></Link>
                </div>
                <div className="telemetry-top">
                    <h1>New Store</h1>
                    <div className="telemetry-top-right">
					<DarkModeSwitch />
                        <AddProducts />
                        <Profile image={profileImage} />
                    </div>
                </div>
            </header>
            <main className="telemetry-main">
                <aside className="telemetry-sidebar">
                    <ToggleDropdown tabactive={0} tabactive2={0} />
                </aside>
                <section className="telemetry-content">
                    <form onSubmit={handleSubmit} key={successMessage ? Date.now() : undefined}>
                        <div className="telemetry-setting-tab-content">
                            <div className="telemetry-product">
                                <h2>Add New Store</h2>
                                <div className="telemetry-add-products">
                                    <div>
                                        <div className="telemetry-form-inline">
                                            <h4>Store Title:</h4>
                                            <div className="telemetry-form-box">
                                                <input
                                                    type="text"
                                                    placeholder="Enter the title"
                                                    value={storeTitle}
                                                    onChange={(e) => setStoreTitle(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="telemetry-form-inline">
                                            <h4>Store URL:</h4>
                                            <div className="telemetry-form-box">
                                                <input
                                                    type="url"
                                                    placeholder="Enter the Store URL"
                                                    value={storeSlug}
                                                    onChange={(e) => setStoreSlug(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="telemetry-form-inline">
                                            <h4>Terms & Conditions:</h4>
                                            <div className="telemetry-form-box">
                                                <input
                                                    type="url"
                                                    placeholder="https://my-shop.com/terms-and-conditions/"
                                                    value={termsConditions}
                                                    onChange={(e) => setTermsConditions(e.target.value)}
                                                />
                                                <p>An optional link to your Terms & Conditions that will appear in the EULA (End User License Agreement) and be referenced in the checkout when set.</p>
                                            </div>
                                        </div>
                                        <div className="telemetry-form-inline">
                                            <h4>Support Form:</h4>
                                            <div className="telemetry-form-box">
                                                <input
                                                    type="url"
                                                    placeholder="https://my-shop.com/support-form/"
                                                    value={supportForm}
                                                    onChange={(e) => setSupportForm(e.target.value)}
                                                />
                                                <p>An optional link to your custom Support Form, overriding the built-in form provided in the User Dashboard.</p>
                                            </div>
                                        </div>
                                        <div className="telemetry-form-inline">
                                            <h4>Store Icon:</h4>
                                            <div className="telemetry-form-box">
                                                <div className="telemetry-upload-logo">
                                                    <div className="update-icon">
                                                        <img src={profileicon} alt="Profileicon" />
                                                    </div>
                                                    <div className="upload-action">
                                                        <label>
                                                            Upload <input type="file" ref={fileInputRef} onChange={(e) => setStoreIcon(e.target.files[0])} />
                                                        </label>
                                                        <p>
                                                            200px X 200px
                                                            <br />
                                                            Up to 200KB
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="telemetry-product">
                                <h2>User Dashboard</h2>
                                <div className="telemetry-add-products">
                                    <Notification content="Learn how to embed the User Dashboard right into your website" />
                                    <div>
                                        <div className="telemetry-form-inline">
                                            <h4>User Dashboard URL:</h4>
                                            <div className="telemetry-form-box">
                                                <input
                                                    type="url"
                                                    placeholder="https://my-shop.com/account/"
                                                    value={dashboardUrl}
                                                    onChange={(e) => setDashboardUrl(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="telemetry-form-inline">
                                            <h4>User Dashboard CSS Stylesheet:</h4>
                                            <div className="telemetry-form-box">
                                                <input
                                                    type="url"
                                                    placeholder="http://my-shop.com/assets/css/custom.css"
                                                    value={dashboardCss}
                                                    onChange={(e) => setDashboardCss(e.target.value)}
                                                />
                                                <p>We do not recommend using custom CSS since changes in the User Dashboard HTML might break your styles.</p>
                                            </div>
                                        </div>
                                        <div className="telemetry-form-inline">
                                            <h4>User Dashboard JavaScript:</h4>
                                            <div className="telemetry-form-box">
                                                <input
                                                    type="url"
                                                    placeholder="https://my-shop.com/assets/js/user-dashboard-tweaks.js"
                                                    value={dashboardJs}
                                                    onChange={(e) => setDashboardJs(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" disabled={isSubmitting}>
                                        {isSubmitting ? 'Creating...' : 'Create New 🚀'}
                                    </button>
                                    {successMessage && <p className="success-message">{successMessage}</p>}
                                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </main>
        </div>
    );
}
