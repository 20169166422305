import React, { useState } from "react";

const UninstallReasonsDropdown = ({ onReasonSelect, selectedReason }) => {
    const [isOpen, setIsOpen] = useState(false);
    const reasons = [
        { id: null, label: "All Uninstall Reasons" }, // Option to clear filter
        { id: 1, label: "I only needed the plugin for a short period" },
        { id: 2, label: "I found a better plugin" },
        { id: 3, label: "The plugin broke my site" },
        { id: 4, label: "The plugin suddenly stopped working" },
        { id: 5, label: "I no longer need the plugin" },
        { id: 6, label: "It's a temporary deactivation. I'm just debugging an issue." },
        { id: 7, label: "Other" },
        { id: 9, label: "Skipped" }
    ];

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelect = (reason) => {
        onReasonSelect(reason.id); // Pass `null` when "All Uninstall Reasons" is selected
        setIsOpen(false);
    };

    const selectedLabel = reasons.find(r => r.id === selectedReason)?.label || "All Uninstall Reasons";

    return (
        <div className="telemetry-select-dd no-seprator telemetry-change-length">
            <span onClick={toggleDropdown}>{selectedLabel}</span>

            {isOpen && (
                <ul className="telemetry-dd">
                    {reasons.map((reason) => (
                        <li key={reason.id} onClick={() => handleSelect(reason)}>
                            {reason.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default UninstallReasonsDropdown;
