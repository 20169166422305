import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import { fetchSites } from "../../services/apiService";
import { useNavigate, useParams } from "react-router-dom";
import { useTelemetryContext } from "../../contexts/TelemetryContext";

function SitesTable({ paginationPerPage, searchTerm, userId = null, stateFilter = "", uninstallReason = null }) {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(paginationPerPage);
    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(false);
    const { selectedProduct } = useTelemetryContext();
    const navigate = useNavigate();
    const { storeId, productId } = useParams();

    // Fetch data from the API
    const fetchData = async (page, rowsPerPage) => {
        setFetching(true);
        try {
            const responseData = await fetchSites(
                selectedProduct.id,
                page,
                rowsPerPage,
                userId,
                searchTerm,
                stateFilter || null,
                uninstallReason // Pass uninstall reason
            );

            // Update state with fetched data
            setData(responseData.data || []);
            setTotalRows(responseData.total || 0);
        } catch (error) {
            console.error("Error fetching data:", error);
            alert("Failed to load data. Please try again.");
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        if (selectedProduct && productId && selectedProduct.id.toString() === productId) {
            fetchData(currentPage, perPage);
        }
    }, [selectedProduct, productId, currentPage, perPage, searchTerm, stateFilter, uninstallReason]);

    // Navigate to site details
    const handleSiteDetailClick = (siteId) => {
        navigate(`/stores/${storeId}/plugins/${productId}/sites/${siteId}`);
    };

    // Navigate to user details
    const handleUserDetailClick = (userId) => {
        navigate(`/stores/${storeId}/plugins/${productId}/users/${userId}`);
    };

    // Handle pagination page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Handle rows per page change
    const handleRowsPerPageChange = (newPerPage) => {
        setPerPage(newPerPage);
        setCurrentPage(1);
    };

    // Define table columns
    const columns = useMemo(() => {
        const baseColumns = [
            {
                name: "ID",
                selector: (row) => row.id,
                sortable: true,
                cell: (row) => (
                    <span className="id-span" onClick={() => handleSiteDetailClick(row.id)}>
                        {row.id}
                    </span>
                ),
            },
            {
                name: "Address",
                selector: (row) => row.address,
                sortable: true,
                cell: (row) => (
                    <span onClick={() => handleSiteDetailClick(row.id)} className="link-icon">
                        {row.address}
                    </span>
                ),
            },
            {
                name: "Installed at",
                selector: (row) => row.installed_at,
                sortable: true,
                cell: (row) =>
                    row.installed_at
                        ? new Date(row.installed_at).toLocaleDateString()
                        : "N/A",
            },
            {
                name: "User ID",
                selector: (row) => row.user_id,
                sortable: true,
                cell: (row) => (
                    <span className="link-icon" onClick={() => handleUserDetailClick(row.user_id)}>
                        {row.user_id}
                    </span>
                ),
            },
            {
                name: "SDK Version",
                selector: (row) => row.sdk_version,
                sortable: true,
            },
            {
                name: "Version",
                selector: (row) => row.version,
                sortable: true,
            },
        ];

        // Add conditional columns
        if (stateFilter === "deactivate" || stateFilter === "uninstall") {
            baseColumns.push({
                name: "Reason Detail",
                selector: (row) => row.reason_detail,
                sortable: true,
                cell: (row) => row.reason_detail || "N/A",
            });
        }

        return baseColumns;
    }, [stateFilter]);

    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                    <div className="spinner"></div> {/* CSS Spinner */}
                </div>
            ) : (
                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    responsive
                    progressPending={fetching}
                    progressComponent={(
                        <div className="spinner-container">
                            <div className="spinner"></div>
                        </div>
                    )}
                    highlightOnHover
                    className="custom-data-table"
                />
            )}
        </div>
    );
}

export default SitesTable;
