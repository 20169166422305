import {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom'; // Import useParams to get the id from the route
import ToggleDropdown from '../reused-components/SidebarTabs';
import AddProducts from '../reused-components/AddProducts';
import TelemetrySettingBlock from '../reused-components/ProductType';
import Profile from '../reused-components/Profile';
import smlogo from '../../img/sm-logo.svg';
import profileImage from '../../img/user-profile.png';
import customizerLogo from '../../img/customizer-logo.svg';
import templateIcon from '../../img/template-icon.svg';
import pluginIcon from '../../img/plugin-icon.svg';
import themeIcon from '../../img/theme-icon.svg';
import widgetIcon from '../../img/widget.svg';
import TelemetryBoxProfileUp from '../reused-components/ProfileUpload';
import TelemetryFormBlock from '../reused-components/FormBlock';
import {fetchProductSettings, saveProductSettings} from "../../services/apiService";
import {useTelemetryContext} from "../../contexts/TelemetryContext";
import DarkModeSwitch from "../reused-components/ToggleDarkMode";

export default function Settings() {
    document.title = "Settings | Telemetry";
    const {productId} = useParams();

    const [productData, setProductData] = useState({
        title: '',
        slug: '',
        website: '',
        short_description: '',
        store: ''
    });

    const options = [
        {value: 'option1', label: 'Option 1'},
        {value: 'option2', label: 'Option 2'},
        {value: 'option3', label: 'Option 3'}
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                fetchProductSettings(productId)
                    .then(data => {
                        setProductData(data);
                    })
                    .catch(error => {
                        console.error('Error fetching product settings:', error);
                    });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setProductData({
            ...productData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        saveProductSettings(productData, productId)
            .then(data => {
                alert('Product updated');
            })
            .catch(error => {
                console.error('Error updating product settings:', error);
            });
    };

    return (
        <div className="telemetry-page-wrapper">
            <header className="telemetry-header">
                <div className="telemetry-logo-section">
                    <a href="#"><img src={smlogo} alt="Telemetry"/></a>
                </div>
                <div className="telemetry-top">
                    <h1>Settings</h1>
                    <div className="telemetry-top-right">
					<DarkModeSwitch />
                        <AddProducts/>
                        <Profile image={profileImage}/>
                    </div>
                </div>
            </header>
            <main className="telemetry-main">
                <aside className="telemetry-sidebar">
                    <ToggleDropdown tabactive={1} tabactive2={1}/>
                </aside>
                <section className="telemetry-content">
                    <div className="telemetry-settings-tab">
                        <div className="telemetry-setting-tab-content">
                            <form onSubmit={handleSubmit}>
                                <div className="telemetry-setting-box">
                                    <h2>Product Type</h2>
                                    <div className="telemetry-box-ov">
                                        <TelemetrySettingBlock
                                            name="product_type"
                                            value="Plugin"
                                            iconSrc={pluginIcon}
                                            altText="Plugin"
                                            title="Plugin"
                                            colorClass="telemetry-blue-light"
                                            checked
                                        />
                                        <TelemetrySettingBlock
                                            name="product_type"
                                            value="Theme"
                                            iconSrc={themeIcon}
                                            altText="Theme"
                                            title="Theme"
                                            colorClass="telemetry-green-light"
                                        />
                                        <TelemetrySettingBlock
                                            name="product_type"
                                            value="Widget"
                                            iconSrc={widgetIcon}
                                            altText="Widget"
                                            title="Widget"
                                            colorClass="telemetry-red-light"
                                        />
                                        <TelemetrySettingBlock
                                            name="product_type"
                                            value="Template"
                                            iconSrc={templateIcon}
                                            altText="Template"
                                            title="Template"
                                            colorClass="telemetry-purple-light"
                                        />
                                    </div>
                                </div>
                                <div className="telemetry-setting-box">
                                    <h2>Profile Information</h2>
                                    <div className="telemetry-box-ov">
                                        <TelemetryBoxProfileUp/>
                                        <div className="telemetry-setting-block-2">
                                            <TelemetryFormBlock
                                                label="Title"
                                                inputType="text"
                                                value={productData.title}
                                                name="title"
                                                id="title"
                                                onChange={handleChange}
                                            />
                                            <TelemetryFormBlock
                                                label="Slug"
                                                inputType="text"
                                                value={productData.slug}
                                                name="slug"
                                                id="slug"
                                                infoTitle="Lowercase alphanumeric characters, can include the special characters dash (-) and underline (_) only. If your item is listed on the WordPress.org repository, use the exact same slug."
                                                onChange={handleChange}
                                            />
                                            <TelemetryFormBlock
                                                label="Website / Marketing page URL (optional)"
                                                inputType="url"
                                                name="website"
                                                id="website"
                                                placeholder="Enter your website"
                                                value={productData.website}
                                                onChange={handleChange}
                                            />
                                            <TelemetryFormBlock
                                                label="Short description for disputes (optional)"
                                                inputType="text"
                                                name="short_description"
                                                id="short_description"
                                                placeholder="Login Customizer is/helps/makes ..."
                                                infoTitle="Lowercase alphanumeric characters, can include the special characters dash (-) and underline (_) only. If your item is listed on the WordPress.org repository, use the exact same slug."
                                                value={productData.short_description}
                                                onChange={handleChange}
                                            />
                                            <TelemetryFormBlock
                                                label="Payment Method"
                                                inputType="select"
                                                value="option1"
                                                name="selectBox"
                                                id="selectBox"
                                                width="telemetry-form-block-full"
                                                options={options}
                                                regenerate={false}
                                                copy={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="telemetry-setting-box">
                                    <h2>Keys</h2>
                                    <div className="telemetry-setting-block-2">
                                        <TelemetryFormBlock
                                            label="ID"
                                            inputType="text"
                                            value="6546198431"
                                            name="id"
                                            id="id"
                                            placeholder="Your ID"
                                            copy
                                        />
                                        <TelemetryFormBlock
                                            label="Public Key"
                                            inputType="text"
                                            value="pk_685db264a64a07d94c59266a47a2a"
                                            name="key"
                                            id="key"
                                            placeholder="Your key"
                                            copy
                                        />
                                        <TelemetryFormBlock
                                            label="Secret Key"
                                            inputType="text"
                                            value="sk_FKa[xi=a*JMxGDrQJ)EXy2kb!dxGz"
                                            name="skey"
                                            id="skey"
                                            placeholder="Your Secret Key"
                                            width="telemetry-form-block-full"
                                            regenerate
                                            copy
                                        />
                                        <div className="telemetry-form-block">
                                            <button type="submit">Update</button>
                                            <button type="reset">Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}
